// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { bikeQuoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";
import { bikeQuoteActionsReliance } from "../../../../store/slices/bike_quote-slice-reliance";

import axios from "axios";

let isValid = false;
export const BikeRelianceQuote1 = () => {
  const [closePopUp, setclosePopUp] = useState(true);
  const [isDetails, setIsDetails] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [bikeRegistrationNumber, setBikeRegistrationNumber] = useState('')
  const [count, setCount] = useState(1);
  const [firstLoader, setFirstLoader] = useState(true)
  const [addonsPlace, setAddonsPlace] = useState({})
  const [addons, setAddons] = useState(false);
  const [addonsChecked, setAddonsChecked] = useState('')
  const [isThirdParty, setIsThirdParty] = useState(false)
  const [finalIDVValue, setFinalIDVValue] = useState();
  const [finalPolicyType, setFinalPolicyType] = useState()
  const [finalAddons, setFinalAddons] = useState({});
  const [inputDiscount, setInputDiscount] = useState();

  const [quoteDetailsData, QuoteDetailsData] = useState();
  const [modelValue, setModelValue] = useState("");
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
const [isApiLoaded, setIsApiLoaded] = useState(false)
const [load, setLoad] = useState(false)
  const [popUpsFilterData, setPopUpsFilterData] = useState({})
  const [bikeIsRegistered, setBikeIsRegistered] = useState()
  const [initialPolicyType, setInitialPolicyType] = useState({})
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [uniApiData, setUniApiData] = useState(JSON.parse(localStorage.getItem('apiData')))
const [api, setApi] = useState({})
const [tokenLoaded, setTokenLoaded] = useState(false)
  const [updateIdvValue, setUpdateIdvValue] = useState("0");
const [loader, setLoader] = useState()
const [loader1, setLoader1] = useState()
const [loadApiData, setLoadApiData] = useState({})
const [loadApiData1, setLoadApiData1] =useState({})
const [apiLoads, setApiLoads] = useState()
  const [bikeInfo, setBikeInfo] = useState({});
  const [defaultRadioValue, setDefaultRadioValue] = useState();
  const [makeRequestSuc, setMakeRequestSuc] = useState(false)

const [initialLoad, setInitialLoad] = useState(true)
const [masterID, setMasterID] = useState({})
const [modelRegionId, setModelRegionId] = useState({})
const [rtoRegionCode, setRtoRegionCode] = useState('')
const [manufactureYear, setManufactureYear] = useState()
const [oldPolicyType, setOldPolicyType] = useState()
const [registeredModelValues, setRegisteredModelValues] = useState({})
const [prevClaimValue, setPrevClaimValue] = useState()
const [closeNav, setCloseNav] = useState(false)
const [tileType, setTileType] = useState();
const [preCustomerDetails, setPreCustomerDetails] = useState()
const [ownership, setOwnership] = useState()
const [selectedNCB, setSelectedNCB] = useState(0);
const [inputStatus, setInputStatus] = useState(false)


  // For Counting Number of Checked values in Addons
  // const selectAddonOption = [""];
  // const trueValues = Object.values(inputAddon);
  // trueValues.forEach(function (x) {
  //   selectAddonOption[x] = (selectAddonOption[x] || 0) + 1;
  // });
  // localStorage.setItem(
  //   "checked_addons",
  //   addonsData && selectAddonOption.true == undefined
  //     ? "0"
  //     : selectAddonOption.true
  // );

  const [isValidReq, setIsValidReq] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const dispatch = useDispatch();

  //  State from Redux
  let isValuesRead = useSelector((state) => state.quoteRsa.isInputsRead);

  //Values from LocalStorage
  // const filterData = localStorage.getItem("popupsData");
  // const inputActive = sessionStorage.getItem("inputSet");
  // let registeredModelValue = localStorage.getItem("registered_model");
  // let registeredModelClaimValue = localStorage.getItem(
  //   "registered_model_claim"
  // );
  // const cpaValues = sessionStorage.getItem("cpaValues");

  // const filterData = localStorage.getItem("popupsData");
  // const bikeRegistered = localStorage.getItem("isbikeRegistered");
  // const radioValue = localStorage.getItem("bike_Info");
  // const bikeRegNO = localStorage.getItem("bike_Intro");
  // const finaladdon = sessionStorage.getItem("input_addons_check");
  // const finalDiscount = sessionStorage.getItem("inputs_discount");
  // const finalidvvalue = sessionStorage.getItem("inputs_IDV");
  // const finaladdoninput = sessionStorage.getItem("check_inputs");
  // const modelRegion = localStorage.getItem("regionCode");
  // const masteridvalues = localStorage.getItem("master_ID");
  // const rateValue = localStorage.getItem("ratesValues");
  // const rsaInitIdv = localStorage.getItem("rsaIdvvalue");
  // const requestSta = localStorage.getItem("apiLoaded");
  // const tppdVal = sessionStorage.getItem("tppdVal");
  // const prevOptedAddon = sessionStorage.getItem("prevAddons");
  // const preCustDetails= localStorage.getItem('pre_CustomerDetials')
  // const finalpolicytype = sessionStorage.getItem('inputs_policyType')
  // const bikeDetails = localStorage.getItem("bike_Info");
  // const currentNcbVal = sessionStorage.getItem('currentNcb')
  // // const policyType = sessionStorage.getItem('inputs_policyType')

  // let registeredModelOwnershipValue = localStorage.getItem(
  //   "registered_model_ownership"
  // );

  const filterData = localStorage.getItem("bike_popupsData");
  const bikeRegistered = localStorage.getItem("isBikeRegistered");
  const finalidvvalue = sessionStorage.getItem("bike_inputs_IDV");
  const finalpolicytype = sessionStorage.getItem('bike_inputs_policyType')
  const finaladdon = sessionStorage.getItem("bike_input_addons_check");
  const finalDiscount = sessionStorage.getItem("bike_inputs_discount");



  const prevPolicyType = localStorage.getItem('bike_Info')
  const apiload = localStorage.getItem('api_Load')
  const universalApiData = localStorage.getItem('apiData')
  const masteridvalues= localStorage.getItem('bike_master_ID')
  const modelRegion = localStorage.getItem('bike_regionCode')
  const bikeRegNO = localStorage.getItem('bike_Intro')
  const radioValue = localStorage.getItem('bike_Info')
  
  let registeredModelValue= localStorage.getItem('bike_registered_model')
  let registeredModelClaimValue= localStorage.getItem('bike_registered_model_claim')
  let registeredModelOwnershipValue= localStorage.getItem('bike_registered_model_ownership')
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const preCustDetails= localStorage.getItem('pre_BikeCustomerDetials')
  const policyplaceholder = sessionStorage.getItem('bike_inputs_policyType')
  const inputActive = sessionStorage.getItem("inputSet")

  useEffect(()=>{

    setApiLoads(apiload)
    setPopUpsFilterData(JSON.parse(filterData))
    setBikeIsRegistered (JSON.parse(bikeRegistered))
    setInitialPolicyType(JSON.parse(prevPolicyType))
    setLoadApiData1(JSON.parse(universalApiData))
setMasterID(JSON.parse(masteridvalues))
setModelRegionId(JSON.parse(modelRegion))
bikeRegNO&&setBikeRegistrationNumber(JSON.parse(bikeRegNO).registrationNumber)
setDefaultRadioValue(JSON.parse(radioValue));

setManufactureYear(JSON.parse(radioValue).manufactureYear)
setOldPolicyType(JSON.parse(radioValue).policy)
setRegisteredModelValues(JSON.parse(registeredModelValue))   
setPrevClaimValue(registeredModelClaimValue)
setOwnership(registeredModelOwnershipValue)
setTileType(tileValue);
setFinalPolicyType(finalpolicytype)
setFinalAddons(JSON.parse(finaladdon));
setInputDiscount(finalDiscount);

setPreCustomerDetails(JSON.parse(preCustDetails))
setIsThirdParty( policyplaceholder)
setInputStatus(inputActive)
setFinalIDVValue(finalidvvalue);

  },[filterData, bikeRegNO, registeredModelOwnershipValue, tileValue, registeredModelClaimValue, radioValue, registeredModelValue, apiload, modelRegion, masteridvalues, bikeRegistered, universalApiData, prevPolicyType, policyplaceholder, preCustDetails, inputActive, finalidvvalue, finalpolicytype, finaladdon, finalDiscount])

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(bikeRegistered ? userPrevPolicyExp : Date.now());
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );

  const formatDate = date =>
  `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

// Calculate and format prevPolicyStartDate as one year earlier and one day more
const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));

// prevPolicyEndDate remains the same as startDate
const prevPolicyEndDate = formatDate(startDate);

  /* ---------------- Date Logics End Here ---------------------  */

  /* ---------------------- To Update the Policy type value  -------------------------*/
  const updateIdvTypeValueReliance = (value) => {
    // setIdVValue(value);
  };

  /* --------------------------------------------------------------  /* -------------------------------------------------------------- */
  useEffect(()=>{
    if(finalpolicytype){
      setFinalPolicyType(finalpolicytype)
    }else {
      setFinalPolicyType(defaultRadioValue&&defaultRadioValue.policy)
    }
  },[defaultRadioValue, finalpolicytype])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,bikeInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(bikeQuoteActionsReliance.setInputsRead());

    //  callbackfun()
  }, []);

  const callbackfun =  ()=>{
 

    if (
      Object.keys(bikeInfo).length > 0 &&
      Object.keys(modelRegionId).length > 0 &&
      Object.keys(masterID).length > 0
      &&
      Object.keys(finalAddons).length > 0&&
      selectedNCB!==null&&
      (finalPolicyType!==null||finalPolicyType!==undefined)
    ) {
      setMakeRequestSuc(true)
      sessionStorage.setItem("bikeinputSet", true);
      console.log('i called once')
      dispatch(bikeQuoteActionsReliance.setInputsRead());
    } else {
      sessionStorage.setItem("bikeinputSet", false);
      dispatch(bikeQuoteActionsReliance.setInputsRead());
    }
 
    }
  useEffect(() => {
    setVehicleNumber(
      bikeRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [bikeRegistrationNumber]);
 
  const fetchBikeQuoteReliance = () => {
    console.log('i called from function')
    dispatch(bikeQuoteActionsReliance.setQuoteLoading(true));

    
    
    axios
      .post(
        `${API_ENDPOINT}/quotes/reliance`,
    {
     
        "businessType": "5",
        "vehicleMakeId": "16",
        "vehicleModelId": "39301",
        "rtoLocationId": 571,
        "stateOfRegistrationId": 21,
        "rtoRegionCode": "MH01",
        "zone": "A",
        "seatingCapacity": 2,
        "licencedCarryingCapacity": 0,
        "noOfWheels": 2,
        "exShowroomPrice": 0,
        "idv": 0,
        "registrationNumber": "MH-01-AF-5252",
        "manufacturerMonth": 1,
        "manufacturerYear": "2022",
        "dateOfPurchase": "2022-10-26",
        "typeOfFuel": 1,
        "branchCode": 9202,
        "productCode": 2312,
        "coverFrom": "2024-08-29",
        "coverTo": "2025-08-28",
        "otherSystemName": 1,
        "clientType": 0,
        "agentName": "Direct",
        "ncbReservingLetter": "string",
        "miscTypeOfVehicleId": 0,
        "userId": "100002",
        "isPAToDriverCovered": false,
        "paToDriverCoveredNoOfItems": 1,
        "paToDriverCoveredSumInsured": 200000,
        "isPAToUnNamedPassenger": false,
        "paToUnNamedPassengerCoveredNoOfItems": 2,
        "paToUnNamedPassengerCoveredSumInsured": 100000,
        "isPAToNamedPassengerCovered": false,
        "paToNamedPassengerCoveredNoOfItems": 2,
        "paToNamedPassengerCoveredSumInsured": 100000,
        "isLiabilityToPaidDriverCovered": false,
        "liabilityToPaidDriverCoveredNoOfItems": 1,
        "isTPPDCover": true,
        "tppdSumInsured": 6000,
        "tppdIsChecked": true,
        "isVoluntaryDeductableOpted": false,
        "voluntaryDeductibleSumInsured": 0,
        "isNilDepreciation": false,
        "nilDeprectiationApplicableRate": 1.3,
        "isSecurePlus": false,
        "securePlusApplicableRate": 1.5,
        "isSecurePremium": false,
        "securePremiumApplicableRate": 0.95,
        "isAntiTheftDeviceFitted": false,
        "antiTheftIsChecked": 3,
        "antiTheftNoOfItems": 0,
        "isMotorQuote": false,
        "isHavingValidDrivingLicense": true,
        "isMotorQuoteFlow": false,
        "isElectricalItemFitted": false,
        "electricalItemsTotalSi": 1000000,
        "isNonElectricalItemFitted": false,
        "nonElectricalItemsTotalSi": 100,
        "ncbEligibilityCriteria": "2",
        "previousNcb": "20",
        "isNcbApplicable": true,
        "isPAToOwnerDriverCovered": true,
        "prevYearPolicyEndDate": "2024-08-28",
        "prevYearPolicyNo": "8686948888564865",
        "prevYearPolicyStartDate": "2023-08-29",
        "isPreviousPolicyDetailsAvailable": "true",
        "prevYearPolicyType": "1",
        "sourceSystemId": "100002",
        "authToken": "Pass@123",
        "basicLiability": true,
        "newVehicle": false,
        "basicODCoverage": true,
        "biFuelIsMandatory": false,
        "biFuelSumInsured": 100,
        "biFuelFuelType": "LPG",
        "biFuelKit": false,
        "biFuelIsChecked": false,
        "biFuelIsLpgCng": false,
        "name": "Banavath Preetham Kumar",
        "phoneNo": "9381624403",
        "email": "rapthekode@gmail.com",
    

    },
    
    
    
        //   {


     
      //     businessType: bikeIsRegistered ? 5 : 1,
      //     vehicleMakeId:
      //           Object.values(masterID).length < 1
      //             ? null
      //             : masterID.reliance[0].makeId,
      //             vehicleModelId:
      //                   Object.values(masterID).length < 1
      //                     ? null
      //                     : masterID.reliance[0].modelId,
      //                     rtoLocationId: Math.floor(modelRegionId.reliance.modelRegionId),
      //                     stateOfRegistrationId: Math.floor(modelRegionId.reliance.stateId),
      //                     rtoRegionCode: modelRegionId.reliance.regionCode.replace("-", ""),
      //                     zone: modelRegionId.reliance.modelZoneName,
      //       "seatingCapacity": 2,
      //       "licencedbikeryingCapacity": 0,
      //       "noOfWheels": 2,
      //       "exShowroomPrice": 0,
      //       idv: finalIDVValue==(''||null||undefined)?0:  (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' )?0: finalIDVValue,
      //       registrationNumber: bikeIsRegistered
      //       ?  bikeRegistrationNumber.replace(/(\w{2})(\d{2})(\w{2})(\d+)/, '$1-$2-$3-$4')
      //       : `${modelRegionId.regionCode}-NEW`,
      //       registrationDate: initialPolicyType.registrationDate,
      //       "manufacturerMonth": 1,
      //       manufacturerYear: manufactureYear,
      //       dateOfPurchase: initialPolicyType.registrationDate,
      //       "typeOfFuel":(initialPolicyType.fuelType == 'petrol'&&1) ||(initialPolicyType.fuelType == 'diesel'&&2)||(initialPolicyType.fuelType == 'petrol+cng'&&5)||(initialPolicyType.fuelType == 'electric'&&6),
      //       "branchCode": 9202,
      //        productCode:finalPolicyType==(''||null||undefined)? bikeIsRegistered?  (oldPolicyType=="comprehensive" && 2312) || (oldPolicyType=="thirdParty"&&2348) || (oldPolicyType=="ownDamage"&&2309)    :oldPolicyType=='bundled'?2375:2370 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && 2312) || (finalPolicyType=="thirdParty"&&2348) || (finalPolicyType=="ownDamage"&&2309)    :finalPolicyType=='bundled'?2375:2370 ,
      //        coverFrom: policyStartDate
      //        .toLocaleDateString("en-GB")
      //        .replace(/\//g, "-")
      //        .split("-")
      //        .reverse()
      //        .join("-"), // user inpuut
      //        coverTo: (!bikeIsRegistered&&(finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
      //        .replace(/\//g, "-")
      //        .split("-")
      //        .reverse()
      //        .join("-"): policyEndDate
      //          .toLocaleDateString("en-GB")
      //          .replace(/\//g, "-")
      //          .split("-")
      //          .reverse()
      //          .join("-"),
      //       "otherSystemName": 1,
      //       "clientType": 0,
      //       "agentName": "Direct",
      //       "ncbReservingLetter": "string",
      //       "miscTypeOfVehicleId": 0,
      //       "userId": "100002",
      //       isPAToDriverCovered:
      //       finalAddons == ("" || null || undefined)
      //         ? false
      //         : finalAddons.paidDrivercover
      //         ? true
      //         : false,
      //       "paToDriverCoveredNoOfItems": 1,
      //       "paToDriverCoveredSumInsured": 200000,
      //       isPAToUnNamedPassenger:
      //       finalAddons == ("" || null || undefined)
      //         ? false
      //         : finalAddons.unnamedpassengerPAcover
      //         ? true
      //         : false,
      //       "paToUnNamedPassengerCoveredNoOfItems": 2,
      //       "paToUnNamedPassengerCoveredSumInsured": 100000,
      //       "isPAToNamedPassengerCovered": false,
      //       "paToNamedPassengerCoveredNoOfItems": 2,
      //       "paToNamedPassengerCoveredSumInsured": 100000,
      //       "isLiabilityToPaidDriverCovered": false,
      //       "liabilityToPaidDriverCoveredNoOfItems": 1,
      //       "isTPPDCover": ( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
      //       "tppdSumInsured": 6000,
      //       "tppdIsChecked": ( ( (finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
      //       "isVoluntaryDeductableOpted": 
      //       inputDiscount==null||undefined?false: inputDiscount=='None'?false:finalPolicyType=="thirdParty"?false: inputDiscount=='None'?false:true,
      //       "voluntaryDeductibleSumInsured": 0,
      //       "isNilDepreciation": false,
      //       "nilDeprectiationApplicableRate": 1.3,
      //       "isSecurePlus": false,
      //       "securePlusApplicableRate": 1.5,
      //       "isSecurePremium": false,
      //       "securePremiumApplicableRate": 0.95,
      //       "isAntiTheftDeviceFitted": false,
      //       "antiTheftIsChecked": 3,
      //       "antiTheftNoOfItems": 0,
      //       "isMotorQuote": false,
      //       "isHavingValidDrivingLicense": true,
      //       "isMotorQuoteFlow": false,
      //       "isElectricalItemFitted": false,
      //       "electricalItemsTotalSi": 1000000,
      //       "isNonElectricalItemFitted": false,
      //       "nonElectricalItemsTotalSi": 100,
      //       "ncbEligibilityCriteria": "2",
      //       "previousNcb":  ((registeredModelValues &&
      //         registeredModelValues.prevNCB == (null || undefined)) ||
      //       prevClaimValue == "Yes")
      //         ? 0
      //         : registeredModelValues && registeredModelValues.prevNCB,
      //       "isNcbApplicable": bikeIsRegistered?   prevClaimValue == "Yes" ? false : true:false,
      //       "currentNcb": selectedNCB&&
      //       ((registeredModelValues &&
      //         registeredModelValues.prevNCB == (null || undefined)) ||
      //       prevClaimValue == "Yes"
      //         ? 0
      //         : selectedNCB),
      //       "isPAToOwnerDriverCovered": true,
      //       "nomineeName": "preetham",
      //       "nomineeAddress": "rmm",
      //       "nomineeRelationship": "cousin",
      //       "nomineeDob": "17/09/1990",
      //       "cpaCovertenure": 1,
      //       "name": preCustomerDetails.name,
      //       "phoneNo":preCustomerDetails.mobileNo,
      //       "email": preCustomerDetails.email,
      //       "sourceSystemId": "100002",
      //       "authToken": "Pass@123",
      //       "basicLiability": true,
      //       "biFuelKit": false,
      //       "newVehicle": false,
      //       "basicODCoverage": true,
      //       "prevYearInsurer": 4,
      //       "prevYearPolicyEndDate": bikeIsRegistered ? prevPolicyEndDate.split("-").reverse().join("-")  : "",
      //       "prevYearPolicyNo": "8686948888564865",
      //       "prevYearPolicyStartDate": bikeIsRegistered ? prevPolicyStartDate.split("-").reverse().join("-"): "",
      //       "isPreviousPolicyDetailsAvailable": bikeIsRegistered ?  "true":"false",
      //       "prevYearPolicyType": "1",
      //       "biFuelIsChecked": false,
      //       "biFuelIsMandatory": false,
      //       "biFuelIsLpgCng": false,
      //       "biFuelSumInsured": 100,
      //       "biFuelFuelType": "LPG",
      //       "vehicleVariant": "",
      //       "cubicCapacity": 0,
      //       "isNewVehicle": false,
      //       "isBasicLiability": true,
      //       "isBasicODCoverage": true,
      //       "isBiFuelKit": false,
      //       "antiTheftIsMandatory": false,
      //       "antiTheftPackageName": "",
      //       "paToNamedPassengerCoveredPackageName": "",
      









      // //     businessType: bikeIsRegistered ? 5 : 1,
      // //     vehicleMakeId:
      // //       Object.values(masterID).length < 1
      // //         ? null
      // //         : masterID.reliance[0].makeId,
      // //     vehicleModelId:
      // //       Object.values(masterID).length < 1
      // //         ? null
      // //         : masterID.reliance[0].modelId,
      // //     // "vehicleVariant": "LXI CNG",
      // //     // "cubicCapacity": 998,
      // //     rtoLocationId: Math.floor(modelRegionId.reliance.modelRegionId),
      // //     stateOfRegistrationId: Math.floor(modelRegionId.reliance.stateId),
      // //     rtoRegionCode: modelRegionId.reliance.regionCode.replace("-", ""),
      // //     zone: modelRegionId.reliance.modelZoneName,
      // //     seatingCapacity: 4,
      // //     licencedbikeryingCapacity: 0,
      // //     noOfWheels: 0,
      // //     exShowroomPrice: 0,
      // //     idv: finalIDVValue==(''||null||undefined)?0:  (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' )?0: finalIDVValue,
      // //     registrationNumber: bikeIsRegistered
      // //       ? bikeRegistrationNumber.replace(
      // //         /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
      // //         "$1-$2-$3-$4"
      // //       ).toUpperCase()
      // //       : `${modelRegionId.regionCode}-NEW`,
      // //     // "registrationDate": "2021-07-01", // not req
      // //     registrationDate: initialPolicyType.registrationDate,
      // //     manufacturerMonth: 1,
      // //     manufacturerYear: manufactureYear,
      // //     dateOfPurchase: initialPolicyType.registrationDate,
      // //     typeOfFuel:
      // //       !["" || null || undefined].includes(finalAddons) &&
      // //       finalAddons &&
      // //       finalAddons.externalBiFuelkit
      // //         ? 5
      // //         : (initialPolicyType.fuelType == "petrol" && 1) ||
      // //           (initialPolicyType.fuelType == "diesel" && 2) ||
      // //           ([
      // //             "petrol+cng",
      // //             "cng",
      // //             "lpg",
      // //             "petrol+lpg",
      // //             "inbuilt"
      // //           ].includes(initialPolicyType.fuelType) &&
      // //             5) ||
      // //           (initialPolicyType.fuelType == "electric" && 6),
      // //     branchCode: 9202,
      // //     productCode:finalPolicyType==(''||null||undefined)? bikeIsRegistered?  (oldPolicyType=="comprehensive" && 2311) || (oldPolicyType=="thirdParty"&&2347) || (oldPolicyType=="ownDamage"&&2309)    :oldPolicyType=='bundled'?2374:2371 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && 2311) || (finalPolicyType=="thirdParty"&&2347) || (finalPolicyType=="ownDamage"&&2309)    :finalPolicyType=='bundled'?2374:2371 ,
      // //     // user inpuut
      // //     coverFrom: policyStartDate
      // //       .toLocaleDateString("en-GB")
      // //       .replace(/\//g, "-")
      // //       .split("-")
      // //       .reverse()
      // //       .join("-"), // user inpuut

      // //     coverTo: (!bikeIsRegistered&&(finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
      // //     .replace(/\//g, "-")
      // //     .split("-")
      // //     .reverse()
      // //     .join("-"): policyEndDate
      // //       .toLocaleDateString("en-GB")
      // //       .replace(/\//g, "-")
      // //       .split("-")
      // //       .reverse()
      // //       .join("-"),
      // //     otherSystemName: 1,
      // //     clientType: 0,
      // //     agentName: "Direct",
      // //     ncbReservingLetter: "string",
      // //     miscTypeOfVehicleId: 0,
      // //     userId: "100002",
      // //     isPAToDriverCovered:
      // //       finalAddons == ("" || null || undefined)
      // //         ? false
      // //         : finalAddons.paidDrivercover
      // //         ? true
      // //         : false,
      // //     paToDriverCoveredNoOfItems: 1,
      // //     paToDriverCoveredSumInsured: 200000,

      // //     isPAToUnNamedPassenger:
      // //       finalAddons == ("" || null || undefined)
      // //         ? false
      // //         : finalAddons.unnamedpassengerPAcover
      // //         ? true
      // //         : false,
      // //     paToUnNamedPassengerCoveredNoOfItems: 5,
      // //     paToUnNamedPassengerCoveredSumInsured: 200000,

      // //     isPAToNamedPassengerCovered: false,
      // //     paToNamedPassengerCoveredNoOfItems: 3,
      // //     paToNamedPassengerCoveredSumInsured: 100000,

      // //     isLiabilityToPaidDriverCovered: false,
      // //     liabilityToPaidDriverCoveredNoOfItems: 1,

      // //     isTPPDCover: ( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
      // //     tppdSumInsured: 750000,
      // //     tppdIsChecked:( ( (finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
      // //     isVoluntaryDeductableOpted:
      // //     inputDiscount==null||undefined?false: inputDiscount=='None'?false:finalPolicyType=="thirdParty"?false: inputDiscount=='None'?false:true, // if discount is not zero ? true : false
      // //     voluntaryDeductibleSumInsured:
      // //       inputDiscount == ("" || null || undefined)
      // //         ? "0"
      // //         : (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty')
      // //         ? "0"
      // //         : inputDiscount == "None"
      // //         ? "0"
      // //         : +inputDiscount, /// discount value

      // //     isNilDepreciation:
      // //       finalAddons == ("" || null || undefined)
      // //         ? false
      // //         :(finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty')
      // //         ? false
      // //         : (finalAddons.zeroDepreciation &&
      // //           (finalAddons.consumables ||
      // //             finalAddons.engineProtect ||
      // //             finalAddons.keyLoss))
      // //         ? false
      // //         : finalAddons.zeroDepreciation
      // //         ? true
      // //         : false,
      // //     nilDeprectiationApplicableRate: ratesValues && +ratesValues[0],

      // //     isSecurePlus:
      // //       finalAddons == ("" || null || undefined)
      // //         ? false
      // //         : (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty')
      // //         ? false
      // //         : ((finalAddons.zeroDepreciation &&
      // //             finalAddons.consumables &&
      // //             finalAddons.engineProtect &&
      // //             finalAddons.lossOfPersonalBelongings) ||
      // //             (finalAddons.consumables &&
      // //               finalAddons.engineProtect &&
      // //               finalAddons.lossOfPersonalBelongings) ||
      // //             finalAddons.consumables ||
      // //             finalAddons.engineProtect ||
      // //             finalAddons.lossOfPersonalBelongings) &&
      // //           finalAddons.tyreProtection !== true
      // //         ? true
      // //         : false,
      // //     securePlusApplicableRate: ratesValues && +ratesValues[1],

      // //     isSecurePremium:
      // //       finalAddons == ("" || null || undefined)
      // //         ? false
      // //         : (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty')
      // //         ? false
      // //         :finalAddons&& finalAddons.tyreProtection
      // //         ? true
      // //         : false,
      // //     securePremiumApplicableRate: ratesValues && +ratesValues[2],

      // //     isAntiTheftDeviceFitted: false,
      // //     antiTheftIsChecked: 3,
      // //     antiTheftNoOfItems: 0,

      // //     isMotorQuote: false,
      // //     isHavingValidDrivingLicense: true,
      // //     isMotorQuoteFlow: false,
      // //     isElectricalItemFitted:
      // //       finalAddons == null || undefined
      // //         ? false
      // //         : finalAddons.electricalaccessories
      // //         ? true
      // //         : false, // once ticked electri accesor
      // //     electricalItemsTotalSi:
      // //       finalAddonsInput == null || undefined
      // //         ? 0
      // //         : finalAddonsInput && finalAddonsInput.checkInput1==''?0:finalAddonsInput.checkInput1 , // above box value
      // //     isNonElectricalItemFitted:
      // //       finalAddons == null || undefined
      // //         ? false
      // //         : finalAddons.nonElectricalaccessories
      // //         ? true
      // //         : false, // non electric
      // //     nonElectricalItemsTotalSi:
      // //       finalAddonsInput == null || undefined
      // //         ? 0
      // //         : finalAddonsInput && finalAddonsInput.checkInput2==''?0:finalAddonsInput.checkInput2, // value above

      // //     ncbEligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
      // //     previousNcb:
      // //       ((registeredModelValues &&
      // //         registeredModelValues.prevNCB == (null || undefined)) ||
      // //       prevClaimValue == "Yes")
      // //         ? 0
      // //         : registeredModelValues && registeredModelValues.prevNCB,
      // //     isNcbApplicable:  bikeIsRegistered?   prevClaimValue == "Yes" ? false : true:false, // yes ? true : false
      // //     currentNcb:selectedNCB&&
      // //       ((registeredModelValues &&
      // //         registeredModelValues.prevNCB == (null || undefined)) ||
      // //       prevClaimValue == "Yes"
      // //         ? 0
      // //         : selectedNCB),

      // //     // isPAToOwnerDriverCovered:
      // //     // policyType == "ownDamage" ?false:true,
      // //     isPAToOwnerDriverCovered:
      // //     finalAddons==(''||null||undefined)?((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")? false:true:finalAddons.ownerDriverPAcover?true:false,
      // //     nomineeName: "preetham",
      // //     nomineeAddress: "rmm",
      // //     nomineeRelationship: "cousin",
      // //     nomineeDob: "17/09/1990",
      // //     cpaCovertenure: 1,
          
      // //  "name": preCustomerDetails.name,
      // //  "phoneNo":preCustomerDetails.mobileNo,
      // //  "email": preCustomerDetails.email,

      // //     //   "mandatory": true, // not req
      // //     //  "checked": true, // not requ
      // //     //   "islpgCng": true,  // not req

      // //     sourceSystemId: "100002",
      // //     authToken: "Pass@123",

      // //     basicLiability: true,
      // //     // biFuelKit:true, // other than cng it is false
      // //     biFuelKit: ["petrol+cng", "inbuilt", "cng","lpg","petrol+lpg"].includes(
      // //       defaultRadioValue.fuelType
      // //     )
      // //       ? true
      // //       : !["" || null || undefined].includes(finalAddons) &&
      // //         finalAddons &&
      // //         finalAddons.externalBiFuelkit
      // //       ? true
      // //       : false, // other than cng it is false
      // //     newVehicle: bikeIsRegistered? false:true,
      // //     basicODCoverage:
      // //     ( ( (finalPolicyType==undefined||null))?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty")?false:true,

      // //     prevYearInsurer: 4,
      // //     prevYearPolicyEndDate: bikeIsRegistered ? prevPolicyEndDate.split("-").reverse().join("-")  : "",

      // //     prevYearPolicyNo: "8686948888564865",

      // //     prevYearPolicyStartDate: bikeIsRegistered ? prevPolicyStartDate.split("-").reverse().join("-"): "",
      // //     isPreviousPolicyDetailsAvailable: "true",
      // //     prevYearPolicyType: "1",

      // //     // biFuelIsChecked: true,
      // //     biFuelIsChecked: false,
      // //     biFuelIsMandatory: false,
      // //     // biFuelIsLpgCng:  true,
      // //     biFuelIsLpgCng: ["petrol+cng", "inbuilt", "cng","lpg","petrol+lpg"].includes(
      // //       defaultRadioValue.fuelType
      // //     )
         
      // //       ? true
      // //       : false,
      // //     biFuelSumInsured:
      // //       finalAddonsInput == null || undefined
      // //         ? 0
      // //         : finalAddonsInput && finalAddonsInput.checkInput3==''?0:finalAddonsInput.checkInput3, // from addons bifuel input
      // //     biFuelFuelType:
      // //       defaultRadioValue.fuelType == ("petrol+cng" || "cng")
      // //         ? "CNG"
      // //         : "LPG",

      // //     vehicleVariant: "",
      // //     cubicCapacity: 0,
      // //     isNewVehicle: bikeIsRegistered? false:true,
      // //     isBasicLiability: true,
      // //     isBasicODCoverage: true,
      // //     isBiFuelKit: ["petrol+cng", "inbuilt", "cng","lpg","petrol+lpg"].includes(
      // //       defaultRadioValue.fuelType
      // //     )
      // //       ? true:
            
            
      // //       !["" || null || undefined].includes(finalAddons) &&
      // //         finalAddons.externalBiFuelkit
      // //         ? true
      // //         : false,
      // //     antiTheftIsMandatory: false,
      // //     antiTheftPackageName: "",
      // //     paToNamedPassengerCoveredPackageName: "",





          
      //   },

        {
          headers: {
            'authorization':jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
          "x-rsa-type":1
          }
        }
      )
      .then((res) => {

        dispatch(bikeQuoteActionsReliance.addFirstQuoteLoad(false));
        dispatch(bikeQuoteActionsReliance.setApiDataValues(res.data.relianceQuoteResponse ));
        dispatch(bikeQuoteActionsReliance.setQuoteLoading(false));
        
        const sessQuoApiVal = sessionStorage.getItem('bike_sessQuoApiVal')
        if(sessQuoApiVal){
          const sessQuoApiValObj = JSON.parse(sessQuoApiVal)
          if(sessQuoApiValObj.relianceQuoteResponse){
            sessQuoApiValObj.relianceQuoteResponse =res.data.relianceQuoteResponse
          }
          sessionStorage.setItem('bike_sessQuoApiVal', JSON.stringify(sessQuoApiValObj))
        }
            


        // localStorage.setItem("apiData", JSON.stringify(res));
        localStorage.setItem("apiLoaded", true);

localStorage.setItem('ratesValues',JSON.stringify(res&&res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers.length>0?[res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[0].rate,res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[1].rate,res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[2].rate]:[]))
if(res.data.relianceQuoteResponse){

  sessionStorage.setItem('relianceIdvs',JSON.stringify([res.data.relianceQuoteResponse.MotorPolicy.MinIDV,res.data.relianceQuoteResponse.MotorPolicy.MaxIDV]))
} 
sessionStorage.setItem('relianceReqId',res.data.requestId)
      })

      .catch((error) => {
      
        console.log(error)
        if(error){
          dispatch(bikeQuoteActionsReliance.setQuoteLoading(false));
          dispatch(bikeQuoteActionsReliance.setApiDataValues(null));
  
        }
        
      });
    setIsValidReq(false)

  };

  return { fetchBikeQuoteReliance,callbackfun };
};